import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Works from "../components/works"

const Kopvillkor = () => (
  <Layout hero={false}>
    <SEO title="Köpvillkor" />
    <h1>Köpvillkor för Kaffero</h1>
    <p>
      Du som handlar på Kaffero intygar att du läst och godkänt köpvillkoren, har
      fyllt 18 år, alternativt har målsmans tillstånd för att genomföra köpet.
      Vi levererar i dagsläget endast till Sverige. Kaffero ägs och drivs av det
      svenskregistrerade företaget Ekegren Holding AB. Vi innehar F-skattesedel
      och alla priser är inkl. moms. Organisationsnummer 55XXXXXX.
    </p>

    <h2>Frakt & Leverans</h2>

    <p>
      Alla leveranser är fraktfria. Leveranstiden för kaffet är 1-2 arbetsdagar.
      Då alla våra produkter köps som löpande eller tidsbestämd prenumeration
      levereras kaffet i slutet av varje månad. Beroende på helgdagar etc. så
      levereras kaffet direkt till angedd brevlåda 25:e-31:a i varje månad.
      Sista datum för beställning är den 8:e varje månad. Leveransen sker med
      hjälp av PostNord eller DHL.
    </p>

    <h2>Priser</h2>

    <p>
      Alla priser för privatpersoner anges inklusive svensk moms, för närvarande
      12 % på allt kaffe. För företag anges pris exklusive ovan nämnda moms.
      Kaffero reserverar sig för momshöjningar och andra prisförändringar som vi
      inte kan råda över. Avgift för frakt och fakturaavgift tillkommer.
    </p>

    <h2>Betalning</h2>

    <p>
      Din betalning är krypterad med SSL och alla våra betalningspartners är
      PCI-certifierade efter branschens standard. Vi accepterar kortbetalning
      med VISA, MasterCard. Alla
      betalningar hanteras av Stripe.
    </p>

    <h2>Kunduppgifter & integritet</h2>

    <p>
      När du gör en beställning på Kaffero uppger du dina personuppgifter såsom
      namn, adress, telefonnummer och personnummer. I samband med din
      beställning godkänner du att vi lagrar och använder dina uppgifter för att
      fullfölja våra åtaganden gentemot dig. Vi sparar även all kommunikation
      som vi har med dig via telefon eller e-post. Detta för att kunna
      tillhandahålla bästa möjliga service. Kaffero lämnar inte ut dina
      personuppgifter till tredje part men kan komma att använda dem för att
      kontakta dig med information eller särskilda erbjudanden. Som kund har du
      givetvis rätt att neka till sådana utskick och bli borttagen från vår
      databas. Om du vill bli borttagen eller uppdatera/ändra dina uppgifter så
      är du välkommen att kontakta vår kundtjänst. Enligt personuppgiftslagen
      har du rätt att få den information som vi har registrerat. Om den är
      felaktig, ofullständig eller irrelevant kan du begära att informationen
      ska rättas eller tas bort.
    </p>

    <h2>Reklamationsvillkor</h2>

    <p>
      Om försändelsen försvinner eller om du vid mottagande av kaffeförpackning
      upptäcker skador ska du genast meddela oss detta. Om du inte är nöjd med
      kaffet som du mottagit ska du också ta kontakt med oss. Du som kund bör
      kontrollera att din vara/varor är felfri och korrekt vid mottagandet av
      leveransen. Om något fel skulle upptäckas vid kontrollen så ska du
      kontakta vår kundtjänst via e-post så fort som möjligt. Uppge ditt
      ordernummer samt orsaken till reklamationen så kommer vi att återkomma
      inom kort med detaljerade anvisningar om hur reklamationen går till. Ingen
      reklamation är giltig förrän bekräftad av Kaffero/ Ekegren Holding AB. Vid
      giltig reklamation så skickas en ny försändelse ut snarast. Vi förbehåller
      oss rätten att ersätta en defekt påse med kaffe av samma smak. I de fall
      aktuell månads kaffe är slut, skickas nästa månads smak.
    </p>

    <h2>Ångerrätt</h2>
    <p>
      I enlighet med svenska hälsoförordningar för livsmedel eller andra
      dagligvaror för hushållet som levereras till en konsuments bostad eller
      arbetsplats i ett distributionssystem med regelbunden utkörning accepteras
      tyvärr inte ångerrätt. Detta enligt undantag 7, kapitel 2 i lag 2005:59:
      Avtal om varor och icke finansiella tjänster.
    </p>

    <h2>Avbeställning</h2>
    <p>
      Om du ångrar ditt köp, kort efter beställningen, finns det en möjlighet
      att avbeställa ordern. Detta gör du genom att så snabbt som möjligt
      e-posta vår kundtjänst. Vi gör vårt bästa för att hinna hantera din
      avbeställning och återkomma så snart som möjligt, men har din order hunnit
      bli expedierad så är en avbeställning ej möjlig. En avbeställning är
      aldrig giltig innan kunden har mottagit en bekräftelse från Kaffero via
      e-post. Läs mer under rubriken ångerrätt.
    </p>

    <h2>Force Majeure</h2>
    <p>
      Händelse såsom krig, naturkatastrof, stridsåtgärder på arbetsmarknaden,
      myndighets beslut, uteblivna leveranser från underleverantörer, fördyrande
      omständigheter samt därmed jämförbar händelse utanför Kaffero:s kontroll,
      vilken ej skäligen kunnat förutses, skall hänföras till force majeure,
      vilket innebär att Kaffero befrias från sina förpliktelser att fullgöra
      ingångna avtal.
    </p>

    <h2>Produktinformation</h2>
    <p>
      Kaffero förbehåller sig rätten att justera priser, prisfel,
      produktinformation, produktbilder och erbjudanden utan notis. De
      produktbilder och informationstexter som finns tillgängliga återspeglar
      varan så gott det går. Vi reserverar oss för eventuella fel som kan
      förekomma på sidan och kan inte heller garantera att alla bilder exakt
      återger produkternas verkliga utseende och beskaffenhet. Bilder kan
      exempelvis skilja sig åt beroende på färginställningar i din dator. All
      bildinformation skall ses som illustrationer. Kaffero friskriver sig för
      tryckfel, fel i information, samt fel i specifikation, på samtliga i vårt
      sortiment förekommande produkter.
    </p>

    <h2>Transportskadat gods</h2>
    <p>
      Om en vara från Kaffero skadas under transport och leverans, oavsett om
      skadan är väl synlig eller dold, skall detta anmälas omgående till
      speditören (vanligtvis posten), dock senast inom 7 dagar.
    </p>

    <p>
      Det är därför viktigt att du noga granskar din försändelse när den
      anländer. Transportskador skall också rapporteras till Kaffero inom 7 dagar
      från mottagandet.
    </p>

    <p>
      Kontakta info@Kaffero för mer information om tillvägagångssätt eller med
      eventuella frågor.
    </p>

    <h2>Beställning</h2>
    <p>
      För det fall beställaren är under 18 år så måste målsmans tillstånd
      inhämtas före köpet genomförs. Beställning som görs i annan persons namn
      utan dennes medgivande, eller på annat sätt som medför att Kaffero lider
      ekonomisk skada, polisanmäls. Vi friskriver oss från tryckfel, fel i
      information eller specifikation, på samtliga förekommande produkter i
      sortimentet. All bildinformation skall ses som illustrationer och kan ej
      heller förväntas återge exakt utseende och beskaffenhet.
    </p>

    <h2>Cookies på Kaffero</h2>
    <p>
      Kaffero använder cookies för att förbättra webbplatsen för besökaren
      exempelvis genom att anpassa webbplatsen efter besökarens önskemål, val
      och intressen, kunna hjälpa dig att hålla reda på vilka varor du lagt i
      din varukorg, kunna hantera ditt köp och anpassa våra tjänster så att du
      får reklam som är relevant för dig och för att kunna fullgöra våra
      åtaganden vid köp.
    </p>

    <p>
      Cookies används bland annat i syfte att kunna registrera statistik över
      användandet av Webbplatsen. Du kan själv välja att stänga av cookies i din
      webbläsares säkerhetsinställningar, notera dock att om du väljer att göra
      detta så kan funktionaliteten av hemsidan begränsas.
    </p>

    <h2>Tillämplighet</h2>
    <p>
      Dessa köpevillkor är tillämpliga där svensk eller annan tillämplig lag ej
      föreskriver villkor som är i konflikt med dessa. För privatkonsumenter
      gäller konsumentköplagen samt Distansavtalslagen som även innefattar skydd
      för säljföretaget. Mer information härom finns på Konsumentverkets
      hemsida, www.kov.se.
    </p>

    <h2>Lagval</h2>
    <p>
      Alla försök till bedrägerier polisanmäls och Kaffero förbehåller sig rätten
      att häva köpet om detta befaras. Tvist enligt dessa villkor ska avgöras
      enligt svensk lag.
    </p>

    <Works />
  </Layout>
)

export default Kopvillkor
